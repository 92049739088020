@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #F5F5F5;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
  height: 300px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#97fcff, var(--color-primary));
  height: 300px !important;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(#0adbe3, #0897e9);
}

:root {
  /* BUTTONS STYLES */
  --btn-primary: #0ADBE3;
  --btn-primary-font: #FFF;

  /* --color-gradient: linear-gradient(90deg, #97fcff, #0ADBE3); */
  --color-gradient: #0ADBE3;

  --color-primary: #0ADBE3;
  --color-background: #363636;

  --color-gray: rgb(211, 211, 211);
  --color-gray-dark: rgb(136, 136, 136);

  --grey:	#363636;
  --blue:	#0897e9;
  /*SECONDARY*/
  --yellow:	#0ADBE3;
  --lightgrey:	#eeeeee;
  /*COMPLEMENTARY*/
  --aqua:	#0adbe3;
  --lilac:	#6b48ff;
}