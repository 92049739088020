.twb-card {
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  
    border-radius: 10px;
    padding: 20px;
    margin: 15px;
  }
  
  .twb-image-container {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #FFF;

    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.4);
  }

  .twb-image-container img {
    max-width: 50px !important;
    max-height: 45px;
  }
  
  .twb-card h3 {
    margin: 15px 0;
    text-align: left;
  }
  
  .twb-card p {
    color: var(--color-gray-dark);
    text-align: left;
  }