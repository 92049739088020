.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
}

.form-container h4 {
    margin: 40px 0 0 0;
  text-align: center;
  color: var(--color-primary) !important;
  font-size: 12pt !important;
  font-family: Open Sans, sans-serif !important;
}

.field {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 85%;
  }
  
  .field label {
    text-align: left !important;
    color: #696969 !important;
  
    text-align: left;
    display: block;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 5px;
  }
  
  input {
    line-height: 50px;
    height: 50px;
    padding: 0 10px;
  
    width: 100%;
    display: block;
    width: 100%;
    border: 1px solid #d6d6d6;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
    border-radius: 4px;
    font-size: 1rem;
  
    color: #333333;
}
  
input[type=submit] {
	border-radius: 10px;
  width: 250px !important;
  margin: auto;
	background-color: var(--btn-primary) !important;
	color: #ffffff !important;
	text-align: center !important;
  border: 1px solid var(--btn-primary);

	font-size: 14pt !important;
	font-family: Open Sans, sans-serif !important;
  margin-bottom: 40px;
  cursor: pointer;
  transition: 0.5s;
}

input[type=submit]:hover {
  background-color: #FFF !important;
  color: var(--color-primary) !important;
  
}

input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
  }
  
input[type=number] { 
   -moz-appearance: textfield;
   appearance: textfield;
}

select {
    line-height: 50px;
    height: 50px;
    padding: 0 10px;

    color: #333333;
    
    width: 100%;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d6d6d6;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
    border-radius: 4px;
    font-size: 1rem;
}

.loading-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin: auto;
    background: #eee;
    width: auto;
    height: 150px;
    border-radius: 5px;
}

.loading-container {
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
}

.loading {
    background: none;
    animation: is-rotating 1s infinite;
    border: 6px solid #cccc;
    border-radius: 50%;
    border-top-color: var(--color-primary);
    height: 100px;
    width: 100px;
  }

  .loading-container p {
      margin: 10px;
      color: #696969;
  }
  
  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }

  
.confirm-image {
  width: 250px !important;
  height: 250px !important;
}

.title-confirm {
  font-size: 24px;
  text-align: center;
}

.p-confirm {
  margin: 10px;
  text-align: center;
}

.btn-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #42CA4F;
  border: 1px solid #42CA4F;
  padding: 5px 15px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 20px;
  color: #ffffff;
  cursor: pointer;
}

#confirm-a {
  text-decoration: none !important;
}

.btn-confirm img {
  width: 30px;
  margin-right: 10px;
}