.container-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-content-container {
  width: 100%;
  height: auto;
  background: url(./images/background.svg);
  background-size: cover;
  
}

.header-page {
  width: 100%;
  margin-top: 5px;
  height: 50px;
}

.info-event-container {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.info-event {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-event img {
  width: 30px;
  height: 30px;
}

.info-event p {
  margin: 10px;
}

@media (max-width: 600px) {  
  .info-event-container {
    width: auto;
  }
}

.logo-absolute-container {
  left: 15%;
  width: 400px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.additional-height {
  height: 60px;
}

h2 {
  margin: 50px 20px;
}

h2, h3 {
  color: #102136;
}

.logo-absolute-container img {
  width: 100%;
  z-index: 3;

}

.line-break {
  width: 60vw;
  height: 1px !important;
  margin: 30px 0;
  background: #0ADBE3;
}

.first-block-container {
  width: 100%;

  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.first-block-container-itens {
  width: 100%;
  z-index: 3;

  height: 100%;
  margin: 120px 0 20px 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  align-items: center;
}

.first-block-text-container {
  height: 75%;
  color: var(--color-gray);
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-direction: column;

  text-align: left !important;
}

.first-block-text-container p {
  margin: 20px;
  z-index: 3;
  max-width: 500px;
  line-height: 25px;
  font-size: 17px;
  text-align: justify;
}

.first-block-text-container h1 {
  max-width: 500px;
  color: var(--color-primary);
  font-size: 32px;
}
span {
  font-size: 22px;
  color: #FFF;
}
.first-block-btn {
  margin-top: 20px;
  padding: 15px 30px;
  border: 1px solid var(--btn-primary);
  border-radius: 10px;
  cursor: pointer;
  background: var(--color-gradient);

  font-family: 'Ubuntu', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: var(--btn-primary-font);
  transition: 0.5s;
}

.first-block-btn:hover, .btn-demo:hover {
  background: none;
  color: var(--btn-primary);
  border-bottom: 1px solid #0ADBE3;
}

.first-block-asset-container {
  z-index: 3;
  height: 75% !important;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.first-block-asset-container iframe {
  max-width: 500px;
  z-index: 3;
  max-height: 400px;
  border-radius: 10px;
  border: none;
}

/* SECOND BLOCK STYLES */

.second-block-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  z-index: 1;
  flex-wrap: wrap;
}

.app-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.app-container div {
  width: 60%;
}

.app-container div h2 {
  text-align: center;
}

.app-container div p {
  font-size: 17px;
  color: var(--color-gray-dark);
}

.app-container div iframe {
  border: none;
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
}

.footer-page {
  width: 100%;
  height: 200px;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  background: var(--color-background);
}

.footer-logo-container {
  width: 400px;
}

.footer-logo-container img {
  width: 100%;
}

.footer-social-medias-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.footer-social-medias-container h4 {
  color: #ccc;
  font-weight: 400;
  margin: 10px;
}

.footer-social-medias {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-social-medias img {
  width: 40px;
  margin: 0 5px;
}

@media (max-width: 1100px) {
  .first-block-container-itens {
    margin-top: 30px;
  }
}

@media (max-width: 900px) {
  .app-container div {
    width: 90%;
  }  
}

@media (max-width: 600px) {
  .first-block-text-container h1 {
    margin: 20px;
  }

  .first-block-text-container p {
    color: #fff;
  }

  .header-page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo-absolute-container {
    left: 0;
    position: relative;
    margin: 0;
  }
}

@media (max-width: 550px) {
  .first-block-asset-container iframe {
    width: 95vw;
  }

  .app-container div h2 {
    text-align: start;
    margin: 20px 0 !important;
  }
}


.modal-container {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000000cc;
  z-index: 99999;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  
}

.modal-subcontainer {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background: #FFF;
  border-radius: 10px;
}

.modal-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: blue;
}

.modal-close-btn {
  border: none;
  padding: 10px;
  background: none;
  cursor: pointer;
}

.modal-close-btn:hover {
  background: #ccc;
}

.final-page-btn {
  margin-top: 50px;
}

.author-container {
  width: 100%;
  height: auto;
  margin: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.author-image-container {
  width: 250px;
  height: 100%;
}

.author-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.author-description {
  max-width: 500px;
  height: 100%;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.author-description h3 {
  text-align: center;
  font-size: 24px;
}

.author-description p {
  margin: 20px 0;
  color: var(--color-gray-dark);
}

.author-social-medias {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.author-social-medias img {
  width: 30px;
  margin-right: 10px;
}


@media (max-width: 1100px) {
  .first-block-container-itens {
    margin-top: 30px;
  }
}

@media (max-width: 790px) {
  .author-social-medias {
    justify-content: center;
  }
}